<template>
  <div
    class="min-h-screen min-w-screen flex flex-col items-center bg-gray-900 p-5"
  >
    <div class="max-w-5xl w-full flex flex-row items-center">
      <div class="flex-1">
        <img
          class="h-8 w-auto"
          src="@/assets/icon-text-cloudsprouter-logo.png"
          alt="Workflow logo"
        />
      </div>
      <router-link
        class="bg-blue-700 text-white text-center px-4 py-2 rounded-md text-sm"
        :to="{ name: 'AuthRedirect' }"
      >
        Login
      </router-link>
    </div>
    <div
      class="p-6 max-w-5xl w-full bg-white shadow-md rounded-md space-y-8 flex flex-col mt-10"
    >
      <p>
        <strong>Cloudsprouter</strong> is a platform built by content creators
        for content creators. Commonly, for content creators on the go,
        <em>cellular data can become a bottleneck</em>
        while trying to constantly produce new content. Some creators need to
        quickly push out video and sometimes tasks such as watermarking and
        uploading different versions of a video can take much more time. Whether
        you want to uniformly watermark your video or post simultaneously across
        your social accounts, cloudsprouter is for you.
      </p>
    </div>
    <div class="max-w-5xl mx-auto mt-10">
      <div class="lg:text-center">
        <p
          class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white"
        >
          Key Features
        </p>
        <p class="mt-4 max-w-2xl text-xl text-gray-400 lg:mx-auto">
          Cloudsprouter has tons of key features, but some features are actually
          hidden and work in the background for you.
        </p>
      </div>

      <div class="mt-10">
        <dl
          class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10"
        >
          <div v-for="feature in features" :key="feature.name" class="relative">
            <dt>
              <div
                class="absolute flex items-center content-center justify-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white"
              >
                <i
                  :class="[feature.iconClass]"
                  class="h-6 w-6 mt-2"
                  aria-hidden="true"
                ></i>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-white">
                {{ feature.name }}
              </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-400">
              {{ feature.description }}
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <div class="max-w-5xl mx-auto mt-16">
      <div class="lg:max-w-2xl lg:mx-auto lg:text-center">
        <h2
          class="text-3xl font-extrabold tracking-tight text-white sm:text-3xl"
        >
          Frequently asked questions
        </h2>
        <p class="mt-4 max-w-2xl text-xl text-gray-400 lg:mx-auto">
          If you've thought of it, it's probably here! If not, drop us an email
          at
          <a
            href="mailto:support@cloudsprouter.com"
            target="_blank"
            class="border-dashed border-b text-blue-600 border-blue-600"
            >support@cloudsprouter.com</a
          >!
        </p>
      </div>

      <div class="mt-10">
        <dl
          class="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10"
        >
          <div v-for="faq in faqs" v-bind:key="faq.title">
            <dt class="font-semibold text-white" v-html="faq.title"></dt>
            <dd class="mt-3 text-gray-400">
              {{ faq.description }}
            </dd>
          </div>

          <!-- More questions... -->
        </dl>
      </div>
    </div>

    <div
      class="max-w-5xl w-full mx-auto p-8 rounded-lg lg:flex lg:items-center mt-10 bg-gray-800"
    >
      <div class="lg:w-0 lg:flex-1">
        <h2
          class="text-3xl font-extrabold tracking-tight text-white sm:text-3xl"
          id="newsletter-headline"
        >
          Sign up for our beta
        </h2>
        <p class="mt-3 max-w-3xl text-lg leading-6 text-gray-300">
          We're currently operating under a closed beta but intend on sending
          out open beta invites soon!
        </p>
      </div>
      <div class="mt-8 lg:mt-0 lg:ml-8">
        <form class="sm:flex">
          <label for="email-address" class="sr-only">Email address</label>
          <input
            id="email-address"
            name="email-address"
            type="email"
            autocomplete="email"
            required
            class="w-full px-5 py-3 border border-transparent placeholder-gray-500 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white sm:max-w-xs rounded-md"
            placeholder="Enter your email"
          />
          <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            <button
              type="submit"
              class="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-blue-500"
              v-on:click="alertUser"
            >
              Notify me
            </button>
          </div>
        </form>
        <p class="mt-3 text-sm text-gray-300">
          We care about the protection of your data. Read our
          <router-link
            class="border-dashed border-b text-blue-600 border-blue-600"
            :to="{ name: 'GeneralPrivacyPolicy' }"
          >
            Privacy Policy </router-link
          >.
        </p>
      </div>
    </div>

    <div class="text-gray-500 flex flex-col text-center mt-10">
      Copyright cloudsprouter 2021. All rights reserved.
      <div class="text-center pt-0.5">
        <router-link
          class="text-gray-400"
          :to="{ name: 'GeneralPrivacyPolicy' }"
        >
          Privacy Policy
        </router-link>
        &middot;
        <router-link class="text-gray-400" :to="{ name: 'GeneralTerms' }">
          Terms of Service
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
const features = [
  {
    name: "Post to Multiple Platforms",
    description:
      "You can add platforms such as YouTube, Facebook, and Twitter as a destination. Upload once and forget the rest!",
    iconClass: "far fa-share-alt fa-fw",
  },
  {
    name: "Import from Cloud Storage",
    description:
      "From Dropbox to OneDrive, users can securly authenicate with 3rd parties to quickly import a video to post across multiple platforms.",
    iconClass: "far fa-briefcase fa-fw",
  },
  {
    name: "Watermark Content",
    description:
      "For both photos and videos, take out the extra time out of your workflow to automatically watermark content before posting.",
    iconClass: "far fa-stamp fa-fw",
  },
  {
    name: "Video Quality",
    description:
      "Videos are automatically processed at the qualities that platforms support. We allow up to 8K video for watermarking (and multiposting).",
    iconClass: "far fa-glasses fa-fw",
  },
  {
    name: "Multiple Users",
    description:
      "Have a social media team or multiple users managing social media? Multiple users can use the same watermarking functionality for continuity.",
    iconClass: "far fa-users fa-fw",
  },
  {
    name: "Download Content",
    description:
      "Need to use a watermarked version in another capacity (or on a currently unsupported platform)? Simply download it!",
    iconClass: "far fa-download fa-fw",
  },
];
const faqs = [
  {
    title: "Why was cloudsprouter made?",
    description:
      "A group of individuals needed a hassle free way to quickly upload to multiple platforms with watermarks. Cloudsprouter was then born.",
  },
  {
    title: "Do you support 4K?",
    description:
      "Yes, we support up to 8K. Yes, that's right 8K! You won't need to worry about the future because we'll be able to handle it.",
  },
  {
    title: "Do you support platform <em>x</em> ?",
    description:
      "Umm, maybe? We currently support Facebook and YouTube, but we are quickly working on adding Twitter, LinkedIn, Vimeo, and others. If there's another one you have in mind, send us an email.",
  },
  {
    title: "Why use cloudsprouter?",
    description:
      "Perhaps you are a vlogger and you would like to publish to Facebook and YouTube (or others). Why upload twice when you can upload once? Oh, did we mention watermarking?",
  },
  {
    title: "How does this work?",
    description:
      "It's simple. Create an account, link your social profiles (yes, it's secure), and that's it. Upload your next groovy video (or photo) and we'll take care of the rest. It's really that simple.",
  },
  {
    title: "Will each platform receive the highest quality?",
    description:
      "Yes and no. We supply each platform of your choice the best quality video that they allow. On Facebook this is currently 1080 while on YouTube, you can upload to 8K. We'll take care of that, all you need to do is upload.",
  },
];
export default {
  data() {
    return {
      features,
      faqs,
    };
  },
  methods: {
    alertUser() {
      alert("Try again in September.");
    },
  },
};
</script>
